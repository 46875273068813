/* src/Product.css */

  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #59b512;
    padding: 10px 20px;
    color: white;
  }
  
  .logo {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .nav ul {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .nav li {
    cursor: pointer;
  }
  
  .contact-info {
    font-size: 0.9em;
  }
  
  
  .discount-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: green;
    color: white;
    padding: 5px;
    font-size: 0.9em;
    border-radius: 5px;
  }
  
  .details {
    min-width: 450px;
  }

  @media screen and (max-width:800px){
    .details{
      min-width: 0px;
    }
  }
  
  .details h1 {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  .rating {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .price {
    margin: 10px 0;
    font-size: 1.8em;
  }
  
  
  
  .original-price {
    text-decoration: line-through;
    color: gray;
  }
  
  .description {
    margin: 10px 0;
  }
  
  .quantity-selector {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .quantity-selector button {
    width: 30px;
    height: 30px;
    background-color: #59b512;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .quantity-selector input {
    width: 40px;
    text-align: center;
    margin: 0 10px;
  }
  
  .add-to-cart {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #59b512;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }
  
  .social-share {
    margin-top: 10px;
  }
  
  .social-share a {
    text-decoration: none;
    color: #59b512;
  }
  

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}