@media screen and (max-width: 600px) {
  footer {
    flex-direction: column;
    width: 390px !important;
    gap: 30px;
  }
  footer .div-1 {
    text-align: justify;
    width: 360px;
  }
  footer .div-2,
  footer .div-3,
  footer .div-4,
  footer .div-5 {
    text-align: center;
    width: 360px;
    font-size: 17px;
    /* border: 1px solid red; */
  }
  footer .div-5 .social-icon-div {
    justify-content: center;
    font-size: 20px;
  }
}