.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px;
  height: 197px;
}

@media Screen and (min-width:600px){
  .swiper-slide {
    width: 500px;
    height: 281px;
  } 
}

@media Screen and (min-width:800px){
  .swiper-slide {
    width: 550px;
    height: 309px;
  } 
}